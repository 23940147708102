/// <reference path="../../css/main.scss" />

.content-wrap {
  background-color: #fff;

  h1 {
    font-weight: bold;
  }
}

#req-section {
    margin-bottom: 5%;
    margin-top: -5%;
}

#table {
    margin-top: 2%;
}

#req-button {
    margin: auto;
}

#existing-req{
  margin-top: 10px;
  background-color: #eee;
  padding: 5px;
  border-radius: 4px;
}

