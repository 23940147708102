/// <reference path="../../css/main.scss" />

#header {
	-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35);
	-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35);
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35);
	background-color: $white;

	.header-group {
		display: inline-block;
		width: calc(100% - 182px);
		text-align: right;

		.header-button {
			margin-left: 5px;
			color: $white;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,.65);
			border: none;

			&:hover {
				background-color: $hyland-green;
			}
		}
	}

	@media(max-width: 992px) {
		.header-group {
			padding-right: 80px;

			#hyland-apps-btn {
				display: none;
			}
		}
	}

	.navbar {
		padding: 0;
		margin-top: 10px;

		.nav-item {
			padding: 0;
			transition: .2s ease-in-out;

			&:hover {
				background-color: $white;

				.fa-chevron-down {
					transform: scaleY(-1);
				}

				.dropdown-menu {
					display: block !important;
					opacity: 1;
					visibility: visible;
					background-color: $white;
					border: none;
					border-radius: 0;
				}
			}

			.nav-link {
				color: #191c1a;

				i {
					transition: .2s ease-in-out;
					vertical-align: text-bottom;
				}

				.dropdown-toggle {
					cursor: default;
				}

				.fa-chevron-down {
					font-weight: 900;
					color: $hyland-green;
					margin-left: 2px;
					font-size: 14px;
				}

				:hover {
					color: #006334;
					text-decoration: underline;
				}
			}

			.dropdown-menu {
				opacity: 0;
				visibility: hidden;
				padding-top: 25px;
				transition: .2s ease-in-out;
				top: 36px;

				.nav-column {
					width: 220px;
				}

				.nav-column-relative {
					position: relative;
				}

				.nav-column-title {
					padding: .25rem 1.5rem;
					color: $hyland-green;
					font-weight: bold;
					font-size: 18px;
				}

				&.one-column {
					min-width: 300px;
				}

				&.two-column {
					min-width: 550px;
				}

				&.three-column {
					min-width: 750px;
				}

				&.four-column {
					min-width: 950px;
				}

				&.all-column {
					min-width: 1140px;
				}

				.dropdown-item {
					color: #191c1a;
					white-space: normal;
					line-height: 18px;
					padding: 6px 25px;

					&:hover {
						background-color: $warm-gray;
					}
				}

				.nav-solution-map-spaced {
					border: 2px solid $tech-blue;
					background-color: $tech-blue;
					color: $white;
					margin-left: 35px;
					position: absolute;
					bottom: 0;
					left: 0;

					&:hover {
						background-color: $white !important;
						color: $tech-blue !important;
					}
				}

				.nav-solution-map {
					border: 2px solid $tech-blue;
					background-color: $tech-blue;
					color: $white;
					margin-left: 24px;

					&:hover {
						background-color: $white !important;
						color: $tech-blue !important;
					}
				}

				.dropdown-toggle {
					cursor: default;
				}

				.dropdown-toggle::after {
					display: none;
				}
			}

			#header-search {
				width: 300px;
				border-color: $hyland-green;
				display: none;
			}

			.header-search-btn {
				border-radius: 0 3px 3px 0 !important;
				padding: 8px;
				display: none;

				i {
					margin: 0 2px 0 2px;
					font-size: 20px;
				}
			}
		}

		.header-logo {
			margin-bottom: -5px;
		}

		@media (max-width: 992px) {
			.container {
				width: 100%;
				max-width: 100%;
				margin-right: auto;
				margin-left: auto;
			}

			.navbar-collapse {
				max-width: 100%;
				max-height: 423px;
				overflow-y: visible;
				overflow-x: visible;

				.nav-item {
					&.dropdown {
						&.show {
							border-radius: 3px;
						}
					}

					.nav-link {
						padding: .5rem 1rem;
					}
				}

				.row {
					margin: 0;
				}

				.dropdown-menu {
					min-width: 100% !important;
					display: none;

					.nav-column {
						max-width: 100% !important;
						width: 100%;
						flex-basis: auto;
						-ms-flex-positive: auto;
						margin-left: 10px;
						margin-right: 10px;

						.nav-column-title {
							display: block;
							border-bottom: 1px solid $gray;
							padding-left: 10px;
						}

						.dropdown-item {
							width: 90%;
						}
					}
				}
			}
		}

		.user-nav {
			list-style: none;
			margin: .5rem 0;
			display: inline-block;

			& li {
				margin-left: .5rem;
				display: inline-block;
				padding: 0;
				padding-right: 1px;
			}
		}
	}

	@media (max-width: 992px) {
		.profile-dropdown-content {
			right: 0;
		}
	}

	.hyland-apps-dropdown-content {
		position: absolute;
		top: 4em;
		right: 0em;
		min-width: 355px;
		cursor: default;
		z-index: 9999;
		border-radius: 12px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: $warm-gray;

		& > .hyland-apps-dropdown-triangle {
			position: absolute;
			width: 0;
			height: 0;
			right: 84px;
			top: -10px;
			z-index: 95;
			box-shadow: 0 2px 10px rgba($black, 0.4);

			&:after {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 15px solid $warm-gray;
				top: -5px;
				right: -20px;
			}
		}

		.hyland-apps-list {
			padding: 15px;
			width: 355px;
			margin-bottom: 0;

			.hyland-apps-tile {
				display: inline-block;
				vertical-align: top;
				height: 120px;
				width: 105px;
				text-align: center;
				padding: 0.3rem;

				a {
					display: inline-block;
					margin: 8px 2px;
					font-size: 0.75em;
					color: $tech-blue;
					text-decoration: none;

					.app-button {
						margin: 5px auto;
						width: 65px;
						height: 65px;
						color: $white;
						border-radius: 12px;
						text-align: center;
						line-height: 65px;
						font-size: 3em;
						font-weight: 600;

						@each $color, $value in $colors {
							&.app-button-#{$color} {
								background-color: $value;
								border: 1px solid $value;

								&:hover:active {
									background-color: darken($value, 10%);
									color: darken($white, 10%);
								}

								&:hover {
									background-color: darken($value, 5%);
									color: darken($white, 5%);
								}
							}
						}

						.app-button-light-blue {
							background-color: #7fdcf2;
							border: 1px solid #7fdcf2;
						}

						.app-button-light-blue:hover:active {
							background-color: #00a0bb;
							color: #e6e6e6;
						}

						.app-button-light-blue:hover {
							filter: brightness(80%);
						}
					}

					& > span {
						display: block;
						font-size: 14px;
						line-height: 16px;
						color: $black;
					}
				}

				&:hover {
					border-color: darken($light-gray, 5%);
				}
			}
		}
	}

	.profile-dropdown-content {
		position: absolute;
		top: 4em;
		right: -3em;
		min-width: 400px;
		cursor: default;
		z-index: 9999;
		box-shadow: 0 2px 10px rgba(0,0,0,.2);
		border: 1px solid $light-gray;
		border-radius: 3px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: $profile-dropdown-white;
		text-align: left;


		& > .profile-dropdown-triangle {
			position: absolute;
			width: 0;
			height: 0;
			right: 84px;
			top: -10px;
			z-index: 95;
			box-shadow: 0 2px 10px rgba($black, 0.4);

			&:after {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 15px solid $profile-dropdown-white;
				top: -5px;
				right: -20px;
			}
		}

		& > .profile-dropdown-information {
			background-color: $profile-dropdown-white;
			padding: 15px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			font-size: .875em;

			.heading {
				display: block;
				color: #4d4f53;
				font-size: 1.25em;
				font-weight: 600;
			}

			p {
				margin: 0;
				line-height: normal;
			}

			.hylander {
				border: 3px solid $hyland-green;
				box-shadow: inset 0 0 0 1px rgba(255,255,255,.3), inset 0 0 0 3px $hyland-green, 0 0 20px rgba(83,190,78,.75);
				height: 125px;
				width: 125px;
				border: 1px solid $medium-gray;
				border-radius: 3px;
			}
		}

		.profile-dropdown-actions {
			background-color: $gray;
			border-top: 1px solid $medium-gray;
			padding: 15px;

			a {
				font-size: .875em;

				&.my-profile-btn {
					background-color: #7fdcf2;
					color: #0f7c96;

					&:hover {
						background-color: #0f7c96;
						color: $gray;
					}
				}

				&.sign-out-btn {
					background-color: $gray;
					color: #4d4f53;
					border: 1px $medium-gray solid;

					&:hover {
						background-color: $medium-gray;
						border-color: $dark-gray;
					}
				}
			}

			.change-password-area {
				padding-top: 10px;

				a {
					text-decoration: none;
				}
			}

			.t-right {
				text-align: right;
			}
		}
	}

	@media (max-width: 768px) {
		.profile-dropdown-content {
			right: 0;
		}
	}
}

.avatar {
	background-image: url("/assets/community/images/default-user.png");
	background-size: 100% !important;
	background-repeat: no-repeat;
	height: 6rem;
	width: 6rem;
	display: inline-block;
	vertical-align: middle;
	margin-right: .5rem;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.avatar-small {
	height: 3.5rem;
	width: 3.5rem;
	background-size: cover;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.panelHeader {
	font-weight: 900;
	font-size: 20px;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: $tech-blue;
}

.panelLilHeader {
	font-weight: 900;
	font-size: 16px;
	color: $tech-blue;
}

ul.horizontal-list li {
	display: inline;
	list-style: none;
	padding: 0;
}

#category-facet {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.03);
}

#categories {
	padding-inline-start: 15px;
}

.slider-modal-thumb {
	width: 150px;
	height: 150px;
}

.tile {
	width: 37px;
	height: 37px;
	color: $medium-gray;
	background: $white;
	border: 2px solid $medium-gray;
	border-radius: 12px;
	padding: 5px 2px;

	&.profile {
		border: 1px solid $hyland-green !important
	}


	&:hover {
		box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.25) !important;
		color: $medium-gray;
		background: #EEE;
		cursor: pointer;
	}

	&:focus {
		border-color: $medium-gray;
		box-shadow: none;
	}
}

.search-form {
	display: inline-block;
	vertical-align: middle;

	input:focus,
	button:focus {
		border-color: $hyland-green;
		box-shadow: none;
	}
}

.search-input {
	height: 37px;
	border: 2px solid $hyland-green;
	border-right: 0;
	border-radius: 12px;
}

.search-button {
	height: 37px;
	background: $hyland-green;
	border: 2px solid $hyland-green;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;

	svg {
		color: $white;
	}
}

.noHoverUnderline {
	color: $tech-blue;

	&:hover {
		color: black;
		text-decoration: none !important;
	}
}

.login {
	background-color: $hyland-green;
	border: 2px solid $hyland-green;
	color: #fff;

	&:hover {
		box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.25) !important;
		color: $tech-blue;
		background: $white;
		cursor: pointer;
	}
}

.header-logo {
	margin-top: 20px;
	margin-left: -8px;
	height: 40px;
}