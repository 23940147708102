/// <reference path="../../css/main.scss" />

$footer-font-size: 12px;

#footer-section{
    background-color:$black;
    color:#fff;
    text-align: center;
    position: relative;
    padding: 0.5em 0;
    width: 100%;
    bottom: 0px;

    .footer-bottom{
        border-top:2px solid $medium-gray;
        
        .col-md-4{
            margin:10px 0 10px 0;
            padding-left:0;
            padding-right:0;

            &:last-child{
                text-align: right;
            }
        }

        @media (max-width: 992px) {
            .col-md-4{
                text-align: center;

                &:last-child{
                    text-align: center;
                }

                .footer-social-area{
                    text-align: center;
                }
            }
        }
    }

    .footer-logo{
        margin-top:35px;
        margin-bottom:10px;
    }

    .footer-link-section{
        a{
            color:#fff;
            margin-left:8px;
            margin-right:8px;
            font-size: $footer-font-size;
            vertical-align: middle;
        }
    }

    .footer-social-area{
        font-size: $footer-font-size;
        text-align: left;

        a{
            color:#fff;
            margin-left:10px;
            font-size:18px;
        }
    }

    .copyright-msg{
        font-size: 14px;
    }
}