/// <reference path="../../css/main.scss" />

.item-slider {
	padding: 5px;
	position: relative;

	.item-slider-header {
		font-size: 26px;
		margin-top: 10px;
		font-weight: bold;
		color: $tech-blue;
	}



	.slider-btn {
		position: absolute;
		top: calc(50% - 0px);
		color: $medium-gray;
		cursor: pointer;
		border: none;
		background: none;

		&.slider-btn-left {
			left: -30px;
		}

		&.slider-btn-right {
			right: -30px;
		}
	}

	$card-height: 280px;

	.solution-row {
		height: $card-height;
		overflow: hidden;
	}

	.card {
		display: flex;
		flex-direction: column;
		padding: 0;
		text-align: center;
		height: $card-height;
		transition: .4s ease-in-out;
		border: 1px solid rgba(0, 0, 0, .2);
		border-radius: 12px;

		&:hover {
			border: 1px solid $hyland-green;
		}

		.card-top {
			flex: 1;
			align-content: center;
			justify-content: center;
			overflow: hidden;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			&.spotlight {
				border-top-right-radius: 12px;
				border-top-left-radius: 12px;
				background: $light-gray;
			}

			&.spotlight-alt {
				border-top-right-radius: 12px;
				border-top-left-radius: 12px;
			}

			$card-img-radius: 45px;

			.circle {
				display: flex;
				justify-items: center;
				align-items: center;
				width: 100px;
				height: 100px;
				margin: 1rem auto;
				border-radius: $card-img-radius + 5;
				background: $hyland-green;
			}

			.circle-alt {
				display: flex;
				justify-items: center;
				align-items: center;
				width: 100px;
				height: 100px;
				margin: 1rem auto;
				border-radius: $card-img-radius + 5;
				background: $hyland-green;
			}


			.card-img-top {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: $card-img-radius * 2;
				height: $card-img-radius * 2;
				border-radius: $card-img-radius;
				background-color: $white;
				margin: auto;

				&.view-all {
					width: 100%;
					background-color: transparent;
					margin: 1rem auto;
				}

				img {
					width: $card-img-radius * 2 * 0.65;
					height: auto;
				}
			}
		}

		.card-bottom {
			flex: 1;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;

			.card-title-area {
				.card-title {
					color: $tech-blue;
					font-weight: 900;
					margin-top: 1rem;
					margin-bottom: 0.5rem;
				}
			}

			.card-body {
				padding: .25rem .75rem;

				.card-text, .card-text + p {
					color: $black;
					font-size: 0.9rem;
					margin: 0;
				}
			}
		}

		&:hover {
			box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
		}

		&.slider-card-btn {
			cursor: pointer;
			color: $tech-blue;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.slider-modal {
		.modal-body {
			padding: 45px;

			.slider-modal-title {
				white-space: nowrap !important;
			}

			.slider-modal-description {
				margin-top: 10px;
			}
		}
	}
}
