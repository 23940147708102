$light-gray: #F1EFEF;
$light-blue: #ebf2f3;
$sharebase-blue: #0071c7;
$gray: #e6e6e6;
$bg: #fafafa;
$warm-gray: #f8f7f2;
$medium-gray: #9a9b9c;
$dark-gray: #54565A;
$tech-blue: #00586F;
$hyland-green: #006334;
$purple: #702B62;
$pink: #dd085a;
$black: #191C1A;
$white: #fff;
$brainware-purple: #664778;
$acuo-turquoise: #00A499;
$pacsgear-turquoise: #2AD2C9;
$bright-blue: #54C8E8;
$yellow: #f9bf1c;
$orange: #f95300;

$profile-dropdown-white:#f5f5f5;

$colors: ( 
	hyland-green: $hyland-green, 
	orange: $orange, 
	purple: $purple, 
	pink: $pink, 
	white: $white, 
	black: $black, 
	light-gray: $light-gray, 
	warm-gray: $warm-gray,
	brainware-purple: $brainware-purple, 
	acuo-turquoise : $acuo-turquoise, 
	pacsgear-turquoise : $pacsgear-turquoise, 
	bright-blue : $bright-blue, 
	tech-blue : $tech-blue, 
	medium-gray : $medium-gray,
	sharebase-blue: $sharebase-blue,
	yellow: $yellow,
);

@each $name, $color in $colors {
	.#{$name}-bg {
		background-color: $color !important;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
	height: 100%;
}

body {
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	height: 100%;
	color: $black;
	border-radius: 12px !important;
}

.link {
	color: #0071C7;
	text-decoration: underline;

	:hover {
		color: #004594;
	}
}

a.home-link {
	color: $hyland-green;
	font-weight: bold;

	&:hover {
		color: $hyland-green !important;
	}
}

svg {
	pointer-events: none;
}

#container-section {
	min-height: calc(100% - 90px - 185px);
}

.nav-tabs {
	.nav-link {
		color: $tech-blue;
	}
}

.tab-pane {
	padding: 20px;
}

#demo-content {
	padding-bottom: 40px;
}

@import '~bootstrap/scss/bootstrap.scss';

.text-success {
	color: $black !important;
}

a.text-success:hover {
	color: $black !important;
}

.pageTitle {
	color: $black !important;
}

.pageSubtitle {
	color: $black !important;
}

h3, h3 strong {
	color: $black !important;
}

.btn {
	border-radius: 12px;
	&.btn-primary {
		background-color: $hyland-green !important;
		border-color: $hyland-green !important;

		&:active, &:visited {
			background-color: $hyland-green !important;
			border-color: $hyland-green !important;
			color: white !important;
		}
	}

	&.btn-outline-primary {
		border-color: $hyland-green;
		color: $dark-gray;
		padding: 4px;

		i {
			font-size: 27px;
			min-width: 28px;
		}

		&:active, &:visited {
			background-color: $hyland-green !important;
			color: $dark-gray !important;
		}
	}

	&.btn-secondary {
		background-color: $orange;
		color: #fff;
		border-color: $orange;

		&:active, &:visited {
			background-color: $orange !important;
			color: #fff !important;
			border-color: $orange !important;
		}
	}

	&.btn-default {
		background-color: $light-gray;
		color: $tech-blue;
		border-color: $medium-gray;

		&:active, &:visited {
			background-color: $light-gray !important;
			color: $tech-blue !important;
			border-color: $medium-gray !important;
		}
	}

	&.btn-outline-default {
		background-color: $bg;
		color: $tech-blue;
		border-color: $tech-blue;

		&:hover {
			background-color: lightblue !important;
			color: $tech-blue !important;
		}
	}

	&.btn-gray {
		background-color: $gray;
		border-color: $gray;
		color: #000;

		&:active, &:visited {
			background-color: $gray !important;
			border-color: $gray !important;
			color: #000 !important;
		}
	}
}

.button-column {
	.btn {
		width: 100% !important;
	}
}

.btn-info {
	color: #fff;
	background-color: $hyland-green;
	border-color: $hyland-green;
}

form {
	margin-bottom: 0;
}

input[type=checkbox] {
	cursor: pointer;
	visibility: hidden;

	&:disabled {
		cursor: not-allowed !important;

		&:checked {
			&:after {
				background-color: $hyland-green !important;
				content: "\2713";
				color: $dark-gray !important;
				margin-bottom: 6px;
				line-height: 15px;
				text-align: center;
			}
		}

		&:after {
			border: 1px solid $tech-blue;
		}
	}

	&:after {
		display: inline-block;
		content: '';
		width: 15px;
		height: 15px;
		border: 1px solid #555855;
		border-radius: 3px;
		background: $white;
		visibility: visible;
	}

	&:checked {
		&:after {
			background-color: $hyland-green;
			content: "\2713";
			color: $white;
			margin-bottom: 6px;
			line-height: 15px;
			text-align: center;
			border: 1px solid $hyland-green;
		}
	}
}

select.form-control {
	&.required {
		&.is-invalid {
			background-image: none;
		}

		&.is-valid {
			background-image: none;
		}
	}
}

.solution-section {
	.button-column {
		margin-top: 20px;

		button, a {
			margin-bottom: 10px;
		}
	}

	.product-image {
		margin-right: 10px;
	}

	.product-info {
		h1 {
			color: $black;
			font-weight: bold;
		}

		p {
			margin: 0;
			font-weight: bold;
		}
	}

	h2 {
		font-size: 24px;
		font-weight: bold;
		margin-top: 20px;
	}
}

.fa-search:before {
	color: white;
}

.search-result-item {
	background-color: #FFFFFF;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 12px;
	height: 100%;
	transition: .5s ease-in-out;
	opacity: 0;
	display: block;
	text-decoration: none;
	border: 1px solid #c5c7c3;
	text-align: center;
}

.search-result-item:hover {
	box-shadow: 0 5px 12px rgba(0, 0, 0, .2);
	text-decoration: none;
	border: 1px solid $hyland-green;
}

.search-result-item p {
	color: #333;
}

.search-facet {
	border: 1px solid lightgray;
	margin-bottom: 1rem;
	padding: 10px;
	color: #333;
	border-radius: 12px;
	font-size: .8rem;
}

.search-facet ul {
	list-style: none;
	padding: 0;

	li {
		display: flex;

		label {
			flex: auto;
		}
	}
}

.search-facet ul li {
	margin: 0;
	padding: 0;
}

.search-facet ul li:first-child {
	margin-top: 5px;
}

.search-facet label {
	margin: 0;
}

.search-facet-header {
	font-weight: bold;
	color: #555855;
	cursor: pointer;
}

.search-results-total {
	text-align: center;
}

.solution-thumbnail {
	width: 58px;
	height: 58px;
	margin: 16px;
}

.thumbnail-container {
	width: 90px;
	height: 90px;
	background-color: white;
	border-radius: 100px;
	margin: auto;
}

.thumbnail-border-wrapper {
	width: 100px;
	height: 100px;
	background-color: white;
	border-radius: 100px;
	margin: auto;
	background: $hyland-green;
	padding: 5px;
}

.try-now-button {
	float: right;
	border-radius: 3px;
	border: 1px solid lightgray;
	background-color: #FAFAFA;
}

.result-plain {
	background-color: $white;
	text-align: center;
	padding: 10px 0;
	text-align: center;
	width: 100%;
}

.result-vanity {
	background: $white;
}

.result-body {
	padding: 1rem;
	min-height: 136px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.search-input-box {
	width: 100%;
	border-radius: 12px;
	padding: 5px;
	margin-bottom: 1rem;
	border: 1px solid lightgray;
}

.pill {
	border-radius: 12px;
	padding: 5px 8px;
	margin: 7px 1rem 10px 0;
	cursor: pointer;
	border: 1px solid #8f918e;
	display: inline-block;
	color: #191c1a;

	&:hover{
		background: #EEE;
	}
}

.appear {
	opacity: 1;
}

#solutions-search-input {
	margin-top: 8px;
}

.collapsed {
	transition: .5s ease-in-out;
	max-height: 0;
	overflow: hidden;
	margin: 0 !important;
}

.opened {
	max-height: 1000vh;
}

.search-facet-header .fa-chevron-down {
	transition: .5s ease-in-out;
	transform: rotate(180deg);
	vertical-align: baseline;
	margin-top: 4px;
}

.rotate {
	transform: rotate(0deg) !important;
}

.right {
	float: right;
}

.search-results {
	margin-top: 7px;
}

@import '../modules/header/_header.scss';
@import '../modules/footer/_footer.scss';
@import '../modules/slider/_slider.scss';
@import '../modules/content/_content.scss';
@import '../modules/forms/_demo-request.scss';
@import '../modules/forms/_hosted-demonstrations.scss';
@import '../modules/carousel/_carousel.scss';

@import "~air-datepicker/dist/css/datepicker.css";
@import "~datatables.net-bs/css/dataTables.bootstrap.css";
@import "~lightslider/src/css/lightslider.css";
@import '~@fortawesome/fontawesome-pro/css/all.css';