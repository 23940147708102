/// <reference path="../../css/main.scss" />

.content-wrap{
    background-color:#fff;
    padding-top:10px;

    h1{
        font-weight: bold;
		color: $black;
    }

	img {
        max-width: 100%;
    }
}

.announcement-card {
	padding: 10px 20px;
	border-top: 6px solid $hyland-green;
	margin-bottom: 1.5rem;
	border-radius: 12px;
	border: 1px solid #C5C7C3;
	transition: 1s ease-in-out;

	&:hover {
		box-shadow: 0 1px 10px rgba(0, 0, 0, .2);
		border: 1px solid $hyland-green;
	}

	& > a:hover {
		color: $black;
	}
}

.result-count {
	padding: 10px;
	text-align: center;
	border: 1px solid lightgray;
}

.announcement-title {
	color: #00586E;
	font-size: 1.25rem;
	font-weight: bold;
	text-decoration: none;

	&:hover {
		color: #00CBEE
	}
}

.announcement-date {
	color: #555;
	float: right;
	font-size: .75rem;
}

.announcement-topic {
	font-size: .8rem;
	margin: 0;
}

.announcement-subscribe {
	transition: .4s ease-in-out;
	padding: .3rem 2.5rem;

	&:hover {
		box-shadow: 0 1px 6px rgba(0, 0, 0, .4);
	}
}