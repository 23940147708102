#add-module {
    padding-top: 5px;
}

#demo-selection {
    width: 50%;
}

#demo-row {
    padding-top: 20px;
    padding: 1rem;
}

li {
    padding: 0.3rem;
    padding-top: 10px;
}

h2 {
    font-size: 1.9rem;
}

#demo-button {
    width: fit-content;
    margin: auto !important;
}

.demo-request {
	padding: 5px;
	position: relative;

	.demo-request-row {
		overflow: hidden;
		margin-bottom: 10px;
	}

	.card {
		display: flex;
		flex-direction: column;
		border: 1px solid rgba(0, 0, 0, .2);

		.card-bottom {
			flex: 1;
			padding: .25rem .75rem;

			.card-title-area {
				.card-title {
					color: $hyland-green;
					margin-top: 1rem;
					margin-bottom: 0.5rem;
				}
			}

			.card-body {

				.card-text, .card-text + p {
					color: $black;
					font-size: 0.9rem;
					margin: 0;
				}
			}
		}

		&:hover {
			box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
			text-decoration: none !important;
		}
	}
}
