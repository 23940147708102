/// <reference path="../../css/main.scss" />

#advertisement-carousel {
	.carousel-header {
		background-position-x: 50%;
		background-position-y: 0px;
		background-size: initial;
		background-repeat: repeat;
		background-attachment: fixed;
		background-color: transparent;
		min-height: 275px;
	}

	.carousel-header:before {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		background: rgba(0,0,0,.3);
	}

	@media (min-width: 576px) {
		.carousel-control-next, 
		.carousel-control-prev{
			width: 25%;
		}

	}
}